import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import WhitePaperScroller from "../components/Whitepaper";
import MenuContextProvider from "../context/MenuContext";

const WhitepaperPage = () => {
  return (
    <MenuContextProvider>
      <Layout pageTitle="Shaman Coin | Whitepaper" page="whitePaper">
        <Header
          btnClass="main-nav__btn"
          extraClassName="site-header-one__fixed-top"
          page="whitePaper"
        />

        <WhitePaperScroller />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default WhitepaperPage;
