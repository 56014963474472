import React from "react";

const BlockTitle = props => {
  const windowWidth = props.windowWidth ? props.windowWidth : 1100;
  return (
    <div className={`block-title text-${props.textAlign} `}>
      {props.paraText && (
        <p style={{ color: windowWidth < 1001 ? "rgba(255,255,255,.7)" : "" }}>
          {props.paraText}
        </p>
      )}
      <h3 style={{ color: windowWidth < 1001 ? "white" : "" }}>
        {props.titleText}
      </h3>
      {props.paraText2 && <p style={{ marginTop: 5 }}>{props.paraText2}</p>}
      {props.paraText3 && <p style={{ marginTop: 5 }}>{props.paraText3}</p>}
      {props.paraText4 && <p style={{ marginTop: 5 }}>{props.paraText4}</p>}
    </div>
  );
};

export default BlockTitle;
