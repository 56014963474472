import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
// import Charity from "../components/Charity";
import Telegram from "../components/Telegram";
import MobileApp from "../components/MobileApp";
import Tokenomics from "../components/Tokenomics";
import Utility from "../components/Utility";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import FAQ from "../components/FAQ";
import Testimonials from "../components/Testimonials";
import MenuContextProvider from "../context/MenuContext";
import Lottie from "react-lottie";
import * as animationData from "../animations/loading.json";
import { useLockBodyScroll } from "../hooks/useScrollBlock";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadStarting, setLoadStarting] = useState(true);
  const [cookies, setCookie] = useCookies(["cookie-name"]);

  const hasVisited = cookies.name === "hasVisited";

  const handleFirstVisit = () => {
    setTimeout(() => setLoadStarting(false), 3600);
    setTimeout(() => setCookie("name", "hasVisited", { path: "/" }), 4200);
    setTimeout(() => setLoading(false), 4200);
  };

  useEffect(() => {
    if (!hasVisited) {
      handleFirstVisit();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <MenuContextProvider>
        <Layout pageTitle="Shaman Coin | Home">
          <Header
            btnClass="main-nav__btn"
            extraClassName="site-header-one__fixed-top"
          />
          <Banner />
          <Tokenomics />
          <Utility />
          <MobileApp />
          <Testimonials />
          <Roadmap />
          <Team />

          <FAQ />
          <Telegram />
          <Footer />
        </Layout>
      </MenuContextProvider>
      {loading && !hasVisited && (
        <LoadingScreen loading={loading} loadStarting={loadStarting} />
      )}
    </>
  );
};

function LoadingScreen({ loading, loadStarting }) {
  useLockBodyScroll();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    overflow: "visible",
    zIndex: 100000,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        background: "white",
        display: loading ? "flex" : "none",
        opacity: loadStarting ? 1 : 0,
        transition: "all 600ms ease-in-out",
        left: 0,
        top: 0,
        zIndex: 10000,
      }}
      className="justify-content-center align-items-center"
    >
      <div
        style={{
          position: "absolute",
          width: 600,
          height: 300,
          zIndex: 11000,
        }}
      >
        <Lottie
          options={defaultOptions}
          height={300}
          width={600}
          isStopped={false}
          isPaused={false}
          style={{ overflow: "visible" }}
        />
      </div>
    </div>
  );
}

export default HomePage;
