import React from "react";
import { Link as ScrollLink } from "react-scroll";

// <Link href="/">
//   <a>Home</a>
// </Link>
//#3e6638
const NavLinks = (page) => {
  return (
    <ul className="main-nav__navigation-box">
      <li></li>
      <li>
        <ScrollLink
          activeClass="current"
          to="tokenomics"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Tokenomics
        </ScrollLink>
      </li>
      <li>
        <a
          href="/whitepaper"
          style={{
            color: page.page === "whitePaper" ? "#3e6638" : "",
          }}
        >
          Whitepaper
        </a>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="roadmap"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Roadmap
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="team"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Team
        </ScrollLink>
      </li>
    </ul>
  );
};

export default NavLinks;
