import React from "react";
import BlockTitle from "../BlockTitle";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

const SmallTeam = () => {
  return (
    <section className="team-one" id="team">
      <img
        src="/images/shapes/team-1-bg-1-1.png"
        className="team-one__bg-shape-1"
        alt=""
      />

      <div className="container" style={{ paddingBottom: 100 }}>
        <BlockTitle
          textAlign="center"
          paraText="Team"
          titleText={`Team Shaman Coin`}
        />
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              style={{ padding: "1.15rem", borderRadius: 8, marginBottom: 24 }}
              className="gradient-bg"
            >
              <div className="team-one__inner d-flex">
                <div className="team-one__image-sm">
                  <img
                    src="/images/team/parker.png"
                    alt=""
                    style={{ width: 80 }}
                  />
                </div>
                <div style={{ marginLeft: 18 }}>
                  <h3
                    style={{
                      fontSize: 24,
                      color: "white",
                      marginBottom: 0,
                      lineHeight: 1,
                    }}
                  >
                    Parker Davies
                  </h3>
                  <p
                    style={{
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 4,
                      color: "white",
                    }}
                  >
                    Project Lead
                  </p>
                  <div className="d-flex justify-content-start">
                    <a
                      href="https://m.facebook.com/parker.davies#_=_"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white", marginRight: 10 }}
                    >
                      <FaFacebook style={{ fontSize: 24 }} />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/parker-davies-3b651a51/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white" }}
                    >
                      <FaLinkedin style={{ fontSize: 24 }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div
              style={{ padding: "1.15rem", borderRadius: 8, marginBottom: 24 }}
              className="gradient-bg"
            >
              <div className="team-one__inner d-flex">
                <div className="team-one__image-sm">
                  <img
                    src="/images/team/harry.png"
                    alt=""
                    style={{ width: 80 }}
                  />
                </div>
                <div style={{ marginLeft: 18 }}>
                  <h3
                    style={{
                      fontSize: 24,
                      color: "white",
                      marginBottom: 0,
                      lineHeight: 1,
                    }}
                  >
                    Harry Fox
                  </h3>
                  <p
                    style={{
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 4,
                      color: "white",
                    }}
                  >
                    Dev Lead
                  </p>
                  <div className="d-flex justify-content-start">
                    <a
                      href="https://www.instagram.com/f0xylettuce/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white", marginRight: 10 }}
                    >
                      <FaInstagram style={{ fontSize: 24 }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmallTeam;

// <div className="col-lg-3 col-md-6 col-sm-12">
//   <div className="team-one__single">
//     <div className="team-one__circle"></div>
//     <div className="team-one__inner">
//       <h3>Harry Fox</h3>
//       <p>Web Developer/Graphic Designer</p>
//       <div className="team-one__image">
//         <img
//           src="/images/team/harry.png"
//           alt=""
//           style={{ width: 161 }}
//         />
//       </div>
//       <div className="team-one__social">
//         <a
//           href="https://www.instagram.com/f0xylettuce/"
//           style={{ color: "#6dbe45" }}
//           target="_blank"
//           rel="noreferrer"
//         >
//           <FaInstagram style={{ fontSize: 24 }} />
//         </a>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-3 col-md-6 col-sm-12">
//   <div className="team-one__single">
//     <div className="team-one__circle"></div>
//     <div className="team-one__inner">
//       <h3>Spencer Schultenover</h3>
//       <p>Communication Director</p>
//       <div className="team-one__image">
//         <img
//           src="/images/team/spencer.png"
//           alt=""
//           style={{ width: 161 }}
//         />
//       </div>
//       <div className="team-one__social">
//         <a
//           href="/#"
//           style={{ color: "#6dbe45" }}
//           target="_blank"
//           rel="noreferrer"
//         >
//           <FaInstagram style={{ fontSize: 24 }} />
//         </a>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-3 col-md-6 col-sm-12">
//   <div className="team-one__single">
//     <div className="team-one__circle"></div>
//     <div className="team-one__inner">
//       <h3>Ben Chaiken</h3>
//       <p>Business Lead</p>
//       <div className="team-one__image">
//         <img
//           src="/images/team/ben.png"
//           alt=""
//           style={{ width: 161, height: 161 }}
//         />
//       </div>
//       <div className="team-one__social" style={{ color: "#6dbe45" }}>
//         <a
//           href="https://www.linkedin.com/in/ben-chaiken-548190145/"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <FaLinkedin style={{ fontSize: 24 }} />
//         </a>
//       </div>
//     </div>
//   </div>
// </div>
