import React from "react";
import BlockTitle from "./BlockTitle";
import { useWindowWidth } from "../hooks/useWindowWidth";

const CTAOne = () => {
  const windowWidth = useWindowWidth();
  const flexPosition =
    windowWidth > 991 ? "justify-content-end" : "justify-content-center";
  return (
    <section className="cta-one" id="utility">
      <img
        src="/images/shapes/cta-1-shape-1.png"
        className="cta-one__bg-shape-1"
        alt=""
      />

      <div className="container">
        <div className={`row ${flexPosition}`}>
          <div
            className="cta-one__moc"
            style={{ marginBottom: windowWidth > 991 ? 0 : 80 }}
          >
            <img
              src="/images/utility.jpg"
              className="cta-one__moc-img"
              alt=""
              style={{ borderRadius: 5 }}
            />
          </div>
          <div className="col-lg-6">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="Utility"
                titleText={`Indigenous Identity is Disappearing at Unprecedented Rates`}
              />
              <div className="cta-one__text">
                <p>
                  The UN predicts that between 50-90% will disappear by 2200
                  <span>
                    <a
                      href="https://www.nicoa.org/2019-international-year-of-indigenous-languages/#:~:text=The%20UN%20predicts%20that%2050,10%2C000%20people%20is%20in%20danger."
                      target="blank"
                    >
                      *
                    </a>
                  </span>
                  . This project exists to change that. But how?
                </p>
              </div>
              <ul className="list-unstyled cta-one__list">
                <li>
                  <img
                    alt="icon"
                    src="/images/resources/icon.svg"
                    style={{ width: 14, marginRight: 8 }}
                  />
                  Incentivize collection and entry of indigenous history.
                </li>
                <li>
                  <img
                    alt="icon"
                    src="/images/resources/icon.svg"
                    style={{ width: 14, marginRight: 8 }}
                  />
                  Create a permanent record utilizing Blockchain and NFT.
                </li>
                <li>
                  <img
                    alt="icon"
                    src="/images/resources/icon.svg"
                    style={{ width: 14, marginRight: 8 }}
                  />
                  Build a detailed library of stories and cultures.
                </li>
              </ul>
              <a href="/whitepaper" className="thm-btn cta-one__btn">
                <span>Read our Whitepaper</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;
