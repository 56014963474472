import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

const AccordionItem = props => {
  const [status, setStatus] = useState(props.status);
  const handleClick = () => {
    setStatus(!status);
  };
  return (
    <div className={`accrodion ${status === true ? "active" : ""}`}>
      <div className="accrodion-inner">
        <div
          className="accrodion-title d-flex justify-content-between align-items-center"
          onClick={handleClick}
        >
          <h4>{props.title}</h4>
          <BsChevronDown
            style={{
              transform: status === true ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 200ms ease-in"
            }}
          />
        </div>
        {status === true ? (
          <div className="accrodion-content">
            <div className="inner">{props.content}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AccordionItem;
