import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { FaTelegram } from "react-icons/fa";

export default function CTAThree() {
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <section className="cta-three">
      <img
        src="/images/shapes/cta-three-bg-1-1.png"
        className="cta-three__bg-1"
        alt=""
      />
      <img
        src="/images/shapes/cta-three-bg-1-2.png"
        className="cta-three__bg-2"
        alt=""
      />
      <Container className="text-center">
        <h3>Join us on Telegram!</h3>
        <p>and stay up to date with all the latest on Shaman Coin</p>
        <div className="d-flex justify-content-center">
          <a
            style={{
              width: 230,
              height: 70,
              border: "1px solid black",
              borderRadius: 4,
              color: buttonHover ? "rgba(0,0,0,.7)" : "black",
              cursor: "default"
            }}
            className="d-flex align-items-center"
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            href="https://t.me/shamancoin"
            target="blank"
          >
            <div
              style={{ width: 68 }}
              className="d-flex align-items-center justify-content-center"
            >
              <FaTelegram style={{ fontSize: 28 }} className="ml-2 mr-2" />
            </div>
            <div style={{ paddingBottom: 9 }}>
              <div style={{ fontSize: 22 }}>Shaman Coin</div>
              <div style={{ fontSize: 13, lineHeight: 1, textAling: "left" }}>
                Join the Conversation
              </div>
            </div>
          </a>
        </div>
      </Container>
    </section>
  );
}
