// External Imports
import { useState, useLayoutEffect } from 'react';

export function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(0);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWindowHeight);
    updateWindowHeight();

    return () => window.removeEventListener('resize', updateWindowHeight);
  });

  const updateWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  return windowHeight;
}
