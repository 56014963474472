import React, { useContext, useState } from "react";
import NavLinks from "./NavLinks";
import { MenuContext } from "../context/MenuContext";
import { FaFacebook, FaTelegram, FaTwitter } from "react-icons/fa";
const MobileMenu = () => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };
  const [linkHover, setLinkHover] = useState("");
  return (
    <div
      className={`animated slideInLeft side-menu__block ${
        true === menuStatus ? "active" : " "
      }`}
    >
      <div
        className="side-menu__block-overlay custom-cursor__overlay"
        onClick={handleMenuClick}
      >
        <div className="cursor"></div>
        <div className="cursor-follower"></div>
      </div>
      <div className="side-menu__block-inner ">
        <div className="side-menu__top justify-content-end">
          <a
            href="/#"
            onClick={handleMenuClick}
            className="side-menu__toggler side-menu__close-btn"
          >
            <img src="/images/shapes/close-1-1.png" alt="" />
          </a>
        </div>

        <nav className="mobile-nav__container">
          <NavLinks />
        </nav>
        <div className="side-menu__sep"></div>
        <div className="side-menu__content">
          <div className="">
            <a href="index.html">
              <img src="/images/resources/logo5.png" width="200" alt="" />
            </a>
            <p className="mt-3">
              A blockchain based project aiming to preserve Indigenous culture
              with NFT
            </p>
          </div>
          <div className="side-menu__social mt-4">
            <a
              href="https://www.facebook.com/shamancoin"
              onMouseEnter={() => setLinkHover("f")}
              onMouseLeave={() => setLinkHover("")}
            >
              <FaFacebook
                style={{
                  color: linkHover === "f" ? "white" : "#3e6638",
                  fontSize: 24,
                  position: "absolute"
                }}
              />
            </a>
            <a
              href="https://t.me/Shamancoin"
              onMouseEnter={() => setLinkHover("t")}
              onMouseLeave={() => setLinkHover("")}
            >
              <FaTelegram
                style={{
                  color: linkHover === "t" ? "white" : "#3e6638",
                  fontSize: 24,
                  position: "absolute"
                }}
              />
            </a>
            <a
              href="https://twitter.com/ShamanCoin"
              onMouseEnter={() => setLinkHover("tw")}
              onMouseLeave={() => setLinkHover("")}
            >
              <FaTwitter
                style={{
                  color: linkHover === "tw" ? "white" : "#3e6638",
                  fontSize: 24,
                  position: "absolute"
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
