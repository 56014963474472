import React from "react";
import BlockTitle from "../BlockTitle";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

const LargeTeam = () => {
  return (
    <section className="team-one" id="team">
      <img
        src="/images/shapes/team-1-bg-1-1.png"
        className="team-one__bg-shape-1"
        alt=""
      />
      <div className="container" style={{ paddingBottom: 100 }}>
        <BlockTitle
          textAlign="center"
          paraText="Team"
          titleText={`Team Shaman Coin`}
        />
        <div className="row" style={{ justifyContent: "center" }}>
          {team?.map((tm) => (
            <TeamMember key={tm?.name} tm={tm} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default LargeTeam;

const TeamMember = ({ tm }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <div className="team-one__single">
        <div className="team-one__circle"></div>
        <div className="team-one__inner">
          <h3>{tm?.name}</h3>
          <p>{tm?.position}</p>
          <div className="team-one__image">
            <img src={tm?.src} alt="" style={{ width: 161 }} />
          </div>
          <Socials fb={tm?.fb} insta={tm?.insta} linked={tm?.linked} />
        </div>
      </div>
    </div>
  );
};

const team = [
  {
    name: "Parker Davies",
    position: "Project Lead",
    src: "/images/team/parker.png",
    fb: "https://m.facebook.com/parker.davies#_=_",
    linked: "https://www.linkedin.com/in/parker-davies-3b651a51/",
  },
  {
    name: "Harry Fox",
    position: "Tech Lead",
    src: "/images/team/harry.png",
    insta: "https://www.instagram.com/f0xylettuce/",
  },
];

const Socials = ({ fb, insta, linked }) => {
  const hasFb = fb !== undefined;
  const hasInsta = insta !== undefined;
  const hasLinked = linked !== undefined;
  console.log(hasFb);
  return (
    <div className="team-one__social">
      {hasFb && (
        <a
          href={fb}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#6dbe45" }}
        >
          <FaFacebook style={{ fontSize: 24 }} />
        </a>
      )}
      {hasLinked && (
        <a
          href={linked}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#6dbe45" }}
        >
          <FaLinkedin style={{ fontSize: 24 }} />
        </a>
      )}
      {hasInsta && (
        <a
          href={insta}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#6dbe45" }}
        >
          <FaInstagram style={{ fontSize: 24 }} />
        </a>
      )}
    </div>
  );
};
