import React from "react";
import { useWindowWidth } from "../hooks/useWindowWidth";

const q2 = [
  "Presale on DxSale",
  "LP Locked",
  "Token Launch on PancakeSwap V2",
  "Topic-specific AMAs",
];

const q3 = [
  "Initial Listings",
  "CMC & CG Listings",
  "Influencer partnerships",
  "First NFT release",
];

const q4 = [
  "Shamanpedia dApp",
  "Certik Audit",
  "Larger Listings",
  "Team Expansion",
  "NFT Partnerships",
];

const q1 = [
  "Full NFT Matrix Launch",
  "Wallet Launch",
  "Documentary Release",
  "Fundraising Event",
];
const FunFact = () => {
  const windowWidth = useWindowWidth();
  return (
    <section className="funfact-one" id="roadmap">
      <div className="container-fluid">
        <div className="block-title">
          <h3 style={{ color: "white", textAlign: "center" }}>Roadmap</h3>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="funfact-one__single">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "white",
                  lineHeight: 1.3,
                  height:
                    windowWidth > 1198
                      ? 230
                      : windowWidth > 767
                      ? 250
                      : windowWidth > 427
                      ? 190
                      : 220,
                }}
              >
                {q2.map((q) => (
                  <div style={{ marginTop: 10 }}>{q}</div>
                ))}
              </div>
              <p>Q2 2024</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="funfact-one__single">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "white",
                  lineHeight: 1.3,
                  height:
                    windowWidth > 1198 ? 230 : windowWidth > 767 ? 250 : 230,
                }}
              >
                {q3.map((q) => (
                  <div style={{ marginTop: 10 }}>{q}</div>
                ))}
              </div>
              <p>Q3 2024</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="funfact-one__single">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "white",
                  lineHeight: 1.3,
                  height:
                    windowWidth > 1198 ? 230 : windowWidth > 767 ? 250 : 230,
                }}
              >
                {q4.map((q) => (
                  <div style={{ marginTop: 10 }}>{q}</div>
                ))}
              </div>
              <p>Q4 2024</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="funfact-one__single">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "white",
                  lineHeight: 1.3,
                  height:
                    windowWidth > 1198 ? 230 : windowWidth > 767 ? 250 : 190,
                }}
              >
                {q1.map((q) => (
                  <div style={{ marginTop: 10 }}>{q}</div>
                ))}
              </div>
              <p>Q1 2025</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
