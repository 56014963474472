import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsNewspaper
} from "react-icons/bs";

const stories = {
  0: {
    name: "Parker Davies",
    title: "Traditional Foods of the Indigenous Mentawai",
    content: `This grub is called a "Batra", it's a high protein, high fat, food source. The grubs were also traditionally used as lamp oil before kerosene was introduced. The entire inside of the grub is a flammable fat that melts into oil when cooked. The Mentawaiians would collect this oil in a shell and use a piece of twine crafted from the the "Baiko" tree bark as a wick.`,
    img: "/images/exampleBlocks/parkersDaughter.png",
    tags: ["Indonesian Islands", "Foods", "Mentawai Tribe"]
  },
  1: {
    name: "Parker Davies",
    title: "Traditional Mentawai Ceremonies",
    content: `After sacrificing a pig for a ceremony the elders in the village examine the trails of the animal’s heart to read a story of the past and make a prediction for the future`,
    img: "/images/exampleBlocks/pigSlaughter.png",
    tags: ["Events", "Religon", "Foods", "Animals"]
  },
  2: {
    name: "Parker Davies",
    title: "Native Foods",
    content: `“Toek” are a traditional delicacy of worm. The worm only lives in specific freshwater creeks and grows in a particular species of wood. The natives cut sections of this tree and haul them to the creek where the toek grow, tie them in place for 4-6 months and come back to harvest the worms.`,
    img: "/images/exampleBlocks/worms.png",
    tags: ["Nature", "Traditions"]
  },
  3: {
    name: "Parker Davies",
    title: "Botanical Knowledge",
    content: `“Nilam” is an herb that grows in the understory of the forest and is the plant patchouli oil is derived from. Traditionally used as a treatment for burns`,
    img: "/images/exampleBlocks/plantLeaves.png",
    tags: ["Medicine", "South Pacific"]
  },
  4: {
    name: "Parker Davies",
    title: "Traditional Diets of Indigenous Mentawai",
    content: `The entire trunk of the sago palm is made up of a starchy carbohydrate, livestock such as pigs, chickens and cows eat the sago raw, the traditional mentawai staple food is sagu that has been grated and the starches are washed out to make a flour that is then cooked in bamboo over an open fire. The bamboo tubes filled with cooked sagu are easily transported into the jungle when hunting and gathering for food on the go.`,
    img: "/images/exampleBlocks/cutTree.png",
    tags: ["Foods", "Agriculture"]
  },
  5: {
    name: "Parker Davies",
    title: "Handicrafts",
    content: `The traditional backpack known as a “karakjak” is weaved from 3 different species of rattan vine. Each vine has been narrowed down to the perfect application after thousands of years of testing.`,
    img: "/images/exampleBlocks/coconuts.png",
    tags: ["Crafts"]
  },
  6: {
    name: "Parker Davies",
    title: "Maximizing Resources",
    content: `These palms are known as “bola”, the young bola leaves are harvested and dried and used as traditional rolling papers. The locals have smoked dried taro leaf for hundreds of years.`,
    img: "/images/exampleBlocks/boats.png",
    tags: ["Nature", "Tobacco"]
  },
  7: {
    name: "Parker Davies",
    title: "Building Techniques",
    content: `The traditional thatched roofing called “tobat” is made from the leaves of the sago palm and uses a different variety of vine for the thatched leaflets than the variety used to attach the leaflets to the structure. The traditional roof can last up to 8 years before needing to be replaced and is the best way to keep the house cool as it provides good insulation from the tropical sun`,
    img: "/images/exampleBlocks/thatchRoof.png",
    tags: ["Buildings", "Liststyle", "Homes"]
  }
};

const Testimonials = props => {
  const [story, setStory] = useState(0);
  const [iconHover, setIconHover] = useState("");

  const storyBack = () => {
    if (story === 0) {
      setStory(7);
    } else {
      setStory(story - 1);
    }
  };

  const storyNext = () => {
    if (story === 7) {
      setStory(0);
    } else {
      setStory(story + 1);
    }
  };
  // <img alt="token-bg" src="/images/resources/tokenTrans.png" />
  return (
    <section className="testimonials-one">
      <Container style={{ paddingBottom: 20 }}>
        <Row>
          <Col xl={6}>
            <div style={{ position: "relative", maxWidth: 496 }}>
              <div className="testimonials-one__icon">
                <div className="testimonials-one__icon-inner">
                  <img
                    src="/images/shapes/testi-qoute-1-1.png"
                    alt=""
                    className="testimonials-img"
                  />
                  <div className="tokenTrans">
                    <img
                      alt="token-bg"
                      src="/images/resources/tokenTrans.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="testimonials-one__image">
                <img
                  src={stories[story].img}
                  alt="Awesome 1"
                  style={{ marginTop: 1 }}
                />
              </div>
            </div>
          </Col>
          <Col xl={6} className="d-flex">
            <div>
              <div className="d-flex align-items-center block-title">
                <BsArrowLeftShort
                  style={{
                    fontSize: 34,
                    opacity: iconHover === "b" ? 0.6 : 1,
                    marginRight: 7,
                    marginBottom: 4
                  }}
                  onClick={storyBack}
                  onMouseEnter={() => setIconHover("b")}
                  onMouseLeave={() => setIconHover("")}
                />

                <p>Sample NFT Records</p>

                <BsArrowRightShort
                  style={{
                    fontSize: 34,
                    opacity: iconHover === "n" ? 0.6 : 1,
                    marginBottom: 4
                  }}
                  onClick={storyNext}
                  onMouseEnter={() => setIconHover("n")}
                  onMouseLeave={() => setIconHover("")}
                />
              </div>
              <BlockTitle
                textAlign="left"
                paraText=""
                titleText={stories[story].title}
              />
              <div>
                <div>
                  <div>
                    <p
                      className="testimonials-one__text"
                      style={{ lineHeight: 1.3, marginBottom: 0 }}
                    >
                      {stories[story].content}
                    </p>
                  </div>
                  <div className="d-flex justify-content-start mt-3 mb-3">
                    {stories[story].tags.map((tag, key) => (
                      <div
                        key={key}
                        style={{
                          background: "rgba(0,0,0,.07)",
                          border: "1px solid rgba(0,0,0,.27)",
                          borderRadius: 3,
                          color: "1px solid rgba(0,0,0,.2)",
                          fontSize: 12,
                          cursor: "default"
                        }}
                        className="mr-2 p-1"
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="testimonials-one__title">
                      <BsNewspaper
                        className="mr-2"
                        style={{ marginBottom: 4 }}
                      />
                      {stories[story].name}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
