import React from "react";
import BlockTitle from "../BlockTitle";
import { BiLinkExternal } from "react-icons/bi";
import { useWindowWidth } from "../../hooks/useWindowWidth";

const wallets = [
  { name: "Burn", address: "" },
  { name: "Marketing", address: "" },
  { name: "Platform", address: "" },
];

const Services = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="service-one"
      id="tokenomics"
      style={{
        paddingTop: windowWidth > 400 ? "" : 100,
      }}
    >
      <div
        className="container"
        style={{
          paddingTop: windowWidth > 1100 ? 100 : windowWidth > 600 ? 240 : 20,
        }}
      >
        <div style={{ position: "relative" }}>
          <BlockTitle
            textAlign="center"
            titleText={`Tokenomics`}
            paraText2={"Initial Supply 1,000,000,000,000,000 Tokens"}
            // paraText3={"7% Transaction Tax"}
          />
          <div
            style={{ position: "absolute", left: "50%", top: -50 }}
            className="tokenBG"
          >
            <img
              src="/images/resources/token.png"
              alt="token"
              style={{
                position: "relative",
                left: "-50%",
                width: 240,
                opacity: 0.45,
                zIndex: -1,
              }}
            />
          </div>
        </div>
        <div className="row">
          <Item
            alt="liquity"
            title="HODL & EARN"
            src="/images/resources/holdGreen.svg"
            content="4% Distributed back to Shaman holders"
          />
          <Item
            alt="liquity"
            title="Liquidity Pool"
            src="/images/resources/liquidGreen.png"
            content="3% Locked to increase price floor"
          />
          <Item
            alt="liquity"
            title="Anti Whale"
            src="/images/resources/antiWhale.svg"
            content="0.1% of total supply transaction limit"
          />
          <div className="d-none d-md-block col-2" />
          <div className="col-lg-8 col-md-12">
            <div className="service-one__single mb-0">
              <div className="service-one__inner" style={{ paddingBottom: 50 }}>
                <div className="service-one__circle"></div>
                <h3 className="mb-1">Transparency</h3>
                <p>Keep track of all of our wallets</p>
                <div className="row pl-5 pr-5 mt-3">
                  {wallets.map((wl) => (
                    <div
                      className="mb-2 mb-md-0 col-12 col-md-4"
                      key={wl?.address}
                    >
                      <div className="wallet-button">
                        <a
                          href={wl.a}
                          className="wallet-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {wl.name}
                          <BiLinkExternal
                            className="ml-2"
                            style={{ marginBottom: 3 }}
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

const Item = ({ title, content, alt, src }) => {
  return (
    <div className="col-md-6 col-lg-4">
      <div className="service-one__single">
        <div className="service-one__inner">
          <div className="service-one__circle"></div>
          <div className="service-one__icon">
            <img alt={alt} src={src} style={{ width: "70%" }} />
          </div>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};
