import React from "react";
import BlockTitle from "./BlockTitle";
import { Link as ScrollLink } from "react-scroll";
import { useWindowWidth } from "../hooks/useWindowWidth";
// import CTAImage1 from "../assets/images/resources/cta-2-moc-1.png";
// import CTAImage2 from "../assets/images/resources/cta-2-moc-2.png";
// import CTAImage3 from "../assets/images/resources/cta-2-moc-3.png";
//
const CTATwo = () => {
  const windowWidth = useWindowWidth();
  return (
    <section
      className="cta-two"
      style={{
        position: "relative",
        paddingBottom: windowWidth < 993 ? 0 : ""
      }}
    >
      <img
        src="/images/shapes/faq-bg-1-1.png"
        className="mobile-app-bg-shape"
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <BlockTitle
                textAlign="left"
                paraText="Platform"
                titleText={`Shamanpedia DApp`}
              />

              <div className="cta-two__text">
                <p>
                  We are actively working on our "Shamanpedia" platform, a place
                  where people all over the world can preserve and share their
                  histories.
                </p>
              </div>
              <ScrollLink
                activeClass="current"
                to="roadmap"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <div
                  className="thm-btn cta-one__btn"
                  style={{ cursor: "default" }}
                >
                  <span>Roadmap</span>
                </div>
              </ScrollLink>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div
              className="cta-two__images d-flex justify-content-end align-items-end flex-column"
              style={{ height: 616 }}
            >
              <img
                src="/images/resources/phoneShaman.png"
                className="sm-screen-move"
                data-wow-duration="1500ms"
                alt=""
                style={{
                  maxWidth: "500px",
                  position: windowWidth < 993 ? "absolute" : "relative",
                  top: windowWidth < 993 ? -80 : 0,
                  zIndex: windowWidth < 993 ? -1 : ""
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// <div className="cta-two__icon-wrap">
//   <div className="cta-two__icon-single">
//     <div className="cta-two__icon">
//       <i className="apton-icon-app-development"></i>
//     </div>
//     <h3>
//       Responsive <br /> Design
//     </h3>
//   </div>
//   <div className="cta-two__icon-single">
//     <div className="cta-two__icon">
//       <i className="apton-icon-computer-graphic1"></i>
//     </div>
//     <h3>
//       Online <br /> Marketing
//     </h3>
//   </div>
// </div>

export default CTATwo;
