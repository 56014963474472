import React from "react";

export default function LogoImage() {
  return (
    <img
      alt="shamanLogoPNG"
      src="/images/resources/token.png"
      style={{ maxWidth: 400 }}
    />
  );
}
