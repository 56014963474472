import React from "react";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import LargeTeam from "./Large";
import SmallTeam from "./Small";

export default function Tokenonics() {
  const windowWidth = useWindowWidth();

  if (windowWidth > 765) {
    return <LargeTeam />;
  } else {
    return <SmallTeam />;
  }
}
