import React, { useState, useEffect } from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { useCookies } from "react-cookie";
import LoadingScreen from "./Loading";
import { isMobile } from "react-device-detect";
import ImageSlides from "react-imageslides";

//Internal
import { useWindowHeight } from "../hooks/useWindowHeight";
import { useWindowWidth } from "../hooks/useWindowWidth";

const pageList = [
  { id: 1, img: "/images/whitepaper/wp1.png" },
  { id: 2, img: "/images/whitepaper/wp2.png" },
  { id: 3, img: "/images/whitepaper/wp3.png" },
  { id: 4, img: "/images/whitepaper/wp4.png" },
  { id: 5, img: "/images/whitepaper/wp5.png" },
  { id: 6, img: "/images/whitepaper/wp6.png" },
  { id: 7, img: "/images/whitepaper/wp7.png" },
  { id: 8, img: "/images/whitepaper/wp8.png" },
  { id: 9, img: "/images/whitepaper/wp9.png" }
];

const shortPages = [
  "/images/whitepaper/wp1.png",
  "/images/whitepaper/wp2.png",
  "/images/whitepaper/wp3.png",
  "/images/whitepaper/wp4.png",
  "/images/whitepaper/wp5.png",
  "/images/whitepaper/wp6.png",
  "/images/whitepaper/wp7.png",
  "/images/whitepaper/wp8.png",
  "/images/whitepaper/wp9.png"
];

export default function WhitePaperScroller() {
  const [loading, setLoading] = useState(true);
  const [loadStarting, setLoadStarting] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobileFull, setMobileFull] = useState(false);
  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();

  const [cookies, setCookie] = useCookies(["cookie-name"]);

  const hasVisited = cookies.wpname === "hasVisitedWp";

  const handleFirstVisit = () => {
    setTimeout(() => setLoadStarting(false), 3600);
    setTimeout(() => setCookie("wpname", "hasVisitedWp", { path: "/" }), 4200);
    setTimeout(() => setLoading(false), 4200);
  };

  useEffect(() => {
    if (!hasVisited) {
      handleFirstVisit();
    }
    //eslint-disable-next-line
  }, []);

  const sidewaysMobile = isMobile && windowWidth > windowHeight;
  useEffect(() => {
    if (sidewaysMobile) {
      setMobileFull(true);
    } else if (!sidewaysMobile) {
      setMobileFull(false);
    }
  }, [sidewaysMobile]);

  return (
    <div style={{ userSelect: "none" }}>
      <div
        className="d-flex justify-content-center align-items-start"
        style={{ height: windowHeight, minHeight: 700, position: "relative" }}
      >
        {pageList.map((page, index) => (
          <div
            key={index}
            style={{
              height: windowHeight,
              overflow: "hidden",
              transform:
                currentPage === page.id
                  ? `translate(0px, 32px)`
                  : currentPage < page.id
                  ? `translate(0px, ${windowHeight}px)`
                  : `translate(0px, -${windowHeight}px)`,
              opacity: currentPage === page.id ? 1 : 0,
              transition: "all 200ms linear",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%"
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={page.img}
              alt="white-paper page"
              style={{
                maxWidth: Math.min(1100, windowWidth)
              }}
            />
          </div>
        ))}

        <ChangePageButton
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setMobileFull={setMobileFull}
        />

        {mobileFull && (
          <MobileFullScreenViewer
            mobileFull={mobileFull}
            setMobileFull={setMobileFull}
            windowHeight={windowHeight}
            sidewaysMobile={sidewaysMobile}
          />
        )}
      </div>
      {loading && !hasVisited && (
        <LoadingScreen loading={loading} loadStarting={loadStarting} />
      )}
    </div>
  );
}

const ChangePageButton = ({
  windowHeight,
  windowWidth,
  currentPage,
  setCurrentPage,
  setMobileFull
}) => {
  const canBack = currentPage !== 1;
  const goBack = () => {
    if (canBack) {
      setCurrentPage(currentPage - 1);
    }
  };

  const canNext = currentPage !== 9;
  const goNext = () => {
    if (canNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        right: 20,
        bottom: 80
      }}
    >
      <div
        style={{
          fontSize: 18,
          color: "black",
          flexDirection: "column",
          width: 70,
          flexShrink: 0,
          background: "#f6f6fb",
          borderRadius: 5,
          height: 110,
          border: "2px solid #2e4d29"
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BsArrowUpShort
          style={{
            fontSize: 40,
            opacity: canBack ? 1 : 0.6,
            color: "black"
          }}
          onClick={goBack}
        />
        Page {currentPage}
        <BsArrowDownShort
          style={{
            fontSize: 40,
            opacity: canNext ? 1 : 0.6,
            color: "black"
          }}
          onClick={goNext}
        />
      </div>
    </div>
  );
};

const MobileFullScreenViewer = ({
  mobileFull,
  setMobileFull,
  windowHeight,
  sidewaysMobile
}) => {
  return (
    <div style={{ height: windowHeight, overflow: "hidden" }}>
      <ImageSlides
        images={shortPages}
        isOpen={mobileFull}
        showPageButton={true}
        onClose={() => setMobileFull(false)}
        noTapClose={sidewaysMobile ? false : true}
      />
    </div>
  );
};
