import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../animations/loading.json";
import { useLockBodyScroll } from "../hooks/useScrollBlock";

export default function LoadingScreen({ loading, loadStarting }) {
  useLockBodyScroll();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    overflow: "visible",
    zIndex: 100000,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        background: "white",
        display: loading ? "flex" : "none",
        opacity: loadStarting ? 1 : 0,
        transition: "all 600ms ease-in-out",
        left: 0,
        top: 0,
        zIndex: 10000
      }}
      className="justify-content-center align-items-center"
    >
      <div
        style={{
          position: "absolute",
          width: 600,
          height: 300,
          zIndex: 11000
        }}
      >
        <Lottie
          options={defaultOptions}
          height={300}
          width={600}
          isStopped={false}
          isPaused={false}
          style={{ overflow: "visible" }}
        />
      </div>
    </div>
  );
}
