import React, { useState } from "react";
import { BsHeartFill } from "react-icons/bs";
import {
  FaFacebook,
  FaTelegram,
  FaTwitter,
  FaReddit,
  FaLinkedin,
} from "react-icons/fa";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  const [linkHover, setLinkHover] = useState("");
  const windowWidth = useWindowWidth();
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-6"
                style={{ paddingRight: windowWidth < 500 ? 0 : "" }}
              >
                <div className="footer-widget footer-widget__about">
                  <a href="index.html">
                    <img
                      src="/images/resources/logo5.png"
                      width={windowWidth > 390 ? 200 : 140}
                      alt=""
                    />
                  </a>
                  <p>
                    A blockchain based project aiming to preserve Indigenous
                    culture with NFTs
                  </p>
                </div>
              </div>

              <div
                className="col-lg-5 col-6"
                style={{ paddingLeft: windowWidth < 500 ? 0 : "" }}
              >
                <div className="footer-widget__links-wrap">
                  <div className="footer-widget pl-5">
                    <h3 className="footer-widget__title">Token</h3>
                    <ul
                      className="list-unstyled footer-widget__links-list"
                      style={{ cursor: "default" }}
                    >
                      <li>
                        <ScrollLink
                          activeClass="current"
                          to="utility"
                          spy={true}
                          smooth={true}
                          offset={-40}
                          duration={500}
                        >
                          Utility
                        </ScrollLink>
                      </li>
                      <li>
                        <a
                          href="./whitepaper.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "rgb(33, 37, 41)" }}
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="current"
                          to="roadmap"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Roadmap
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="current"
                          to="team"
                          spy={true}
                          smooth={true}
                          offset={-10}
                          duration={500}
                        >
                          Team
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <ul className="list-unstyled footer-widget__links-list"></ul>
                  </div>
                  <div className="footer-widget">
                    <ul className="list-unstyled footer-widget__links-list"></ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-center col-12">
                <div className="footer-widget">
                  <div className="footer-widget__social">
                    <a
                      href="https://www.reddit.com/r/ShamanCoin/"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setLinkHover("r")}
                      onMouseLeave={() => setLinkHover("")}
                    >
                      <FaReddit
                        style={{
                          color: linkHover === "r" ? "white" : "#2e4d29",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/shaman-coin/"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setLinkHover("l")}
                      onMouseLeave={() => setLinkHover("")}
                    >
                      <FaLinkedin
                        style={{
                          color: linkHover === "r" ? "white" : "#2e4d29",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/shamancoin"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setLinkHover("f")}
                      onMouseLeave={() => setLinkHover("")}
                    >
                      <FaFacebook
                        style={{
                          color: linkHover === "f" ? "white" : "#2e4d29",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      />
                    </a>
                    <a
                      href="https://t.me/Shamancoin"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setLinkHover("t")}
                      onMouseLeave={() => setLinkHover("")}
                    >
                      <FaTelegram
                        style={{
                          color: linkHover === "t" ? "white" : "#2e4d29",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      />
                    </a>
                    <a
                      href="https://twitter.com/ShamanCoin"
                      target="_blank"
                      rel="noreferrer"
                      onMouseEnter={() => setLinkHover("t")}
                      onMouseLeave={() => setLinkHover("")}
                    >
                      <FaTwitter
                        style={{
                          color: linkHover === "t" ? "white" : "#2e4d29",
                          fontSize: 24,
                          position: "absolute",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container text-center">
            <p>
              © copyright 2021 by Shamancoin.org <br />
              Made in New Orleans with <BsHeartFill style={{ color: "red" }} />
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
