import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import Home from "../pages/home";
import Whitepaper from "../pages/whitepaper";
import TokenLogo from "../pages/logoImage";

export default function Root() {
  return (
    <BrowserRouter>
      <Switch>
        <Route key="landing" exact path="/" component={Home} />,
        <Route
          key="whitepaper"
          exact
          path="/whitepaper"
          component={Whitepaper}
        />
        ,
        <Route
          key="token-logo"
          exact
          path="/token-logo.png"
          component={TokenLogo}
        />
        ,
      </Switch>
    </BrowserRouter>
  );
}

// <Route component={NotFound} />
