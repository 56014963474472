import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";

const FAQ = props => {
  return (
    <section className="faq-one">
      <img
        src="/images/shapes/faq-bg-1-1.png"
        className="faq-one__bg-shape-1"
        alt=""
      />
      <Container style={{}}>
        <BlockTitle
          textAlign="center"
          paraText="Learn More About the Project"
          titleText={`Frequently Asked Questions`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="Why have we not renounced ownership?"
            content=<div>
              <strong>Shaman is a charity token.</strong> Renouncing ownership
              would strip us of the ability to delegate where we send donations
              and the ability to schedule manual burns.{" "}
              <strong>We envision this as a long term project.</strong> The
              entire team plans to continuously grow Shaman Coin. This is not a
              pump and dump.
            </div>
            status={false}
          />
          <AccordionItem
            title="Do we hold AMAs?"
            content=<div>
              Yes! The team has scheduled weekly AMAs as follows.
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div>04/01 2pm CT - Launch AMA</div>
                <div>04/14 2pm CT - Utility AMA</div>
                <div>04/21 2pm CT - Burn Rate AMA</div>
                <div>Further AMAs TBD</div>
              </div>
              These will initally be held via Telegram Voice and will move to
              Twitch later in Q2/Q3 2024
            </div>
            status={true}
          />
          <AccordionItem
            title="Listings"
            content={
              <div>
                <div style={{ marginBottom: 14 }}>
                  <strong>Non-Exchange: </strong>Dextools.io
                </div>
                <div style={{ marginBottom: 14 }}>
                  <strong>Round 1 Goal (Q3/Q4 2024):</strong> Bitmart, Whitebit
                </div>
                <div>
                  <strong>Round 2 Goal (Q4 2024/Q1 2025):</strong> KuCoin,
                  gate.io, Mandala
                </div>
              </div>
            }
            status={false}
          />
          <AccordionItem
            title="Available Charts?"
            content="Dextools.io, Poocoin"
            status={false}
          />
        </div>
      </Container>
    </section>
  );
};

// <AccordionItem
//   title="Audits"
//   content="Dessert swap Pre Launch Audit planned for Q3 2021"
//   status={false}
// />

export default FAQ;
