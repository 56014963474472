// External Imports
import { useState, useLayoutEffect } from 'react';

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();

    return () => window.removeEventListener('resize', updateWindowWidth);
  });

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  return windowWidth;
}
